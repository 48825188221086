export const IntroSecDetails = {
  heading1: "",
  typeWritterHeadings: [
    "I'm Taylor Bird",
    "I grow & nuture world-class teams.",
    "I design cloud-native apps",
    "I bring technology dreams to life",
    "I help enterprises acheive the speed of a start-up.",
    "I help start-ups acheive the success of an enterprise.",
    "What can I do for your business?"
  ],
  heading2: "a cloud-native technology leader based in California",
  btnText: "Download Resume",
};
export const AboutSecDetails = {
  image: {
    img: "images/about-me.jpg",
  },
  information: {
    title1: "About Me",
    name: "Taylor Bird",
    desc1:
      "I combine our passion for design focused in people with advanced development technologies. 350+ clients have procured exceptional results and happiness while working with me.",
    desc2:
      "Delivering work within time and budget which meets client’s requirements is our moto. when an unknown printer took a galley of type and scrambled it to make a type specimen book lorem Ipsum has been the industry's standard. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  moreAbout: [
    {
      num: 18,
      title: "Years Experiance in Technology",
    },
    {
      num: 100,
      title: "Happy Clients",
    },
    {
      num: 2,
      title: "Successful Start-up Acqusitions",
    },
  ],
};

export const ServicesDetails = {
  heading: {
    title: "Our Services",
    text: "How I can help take your next project to new heights! Thousands of clients have procured exceptional results while working with Me.",
  },
  details: [
    {
      name: "Graphic Design",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-palette",
    },
    {
      name: "Web Design",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-desktop",
    },
    {
      name: "UI/UX Design",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "App Design & Develop",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-paint-brush",
    },
    {
      name: "Business Analysis",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-chart-area",
    },
    {
      name: "SEO Marketing",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-bullhorn",
    },
  ],
};

export const MyWorking_Skills = {
  heading: {
    title: "Why I'm Different?",
    text: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
  },
  video: {
    vdo: "http://www.youtube.com/watch?v=7e90gBu4pas",
  },
  working: [
    {
      name: "Unique Design",
      icon: "fas fa-fingerprint",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Fully Customisable",
      icon: "fas fa-cog",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Different Layout",
      icon: "fas fa-layer-group",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Responsive Layout",
      icon: "fas fa-mobile-alt",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Boxed & Wide Layouts",
      icon: "fas fa-expand-alt",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Extensive Documentation",
      icon: "far fa-life-ring",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
  ],
  skills: [
    {
      skill: "Branding & Design",
      percent: 95,
    },
    {
      skill: "Web Development",
      percent: 65,
    },
    {
      skill: "Business Analysis",
      percent: 80,
    },
    {
      skill: "Digital Marketing",
      percent: 75,
    },
  ],
};

export const CallToActionDetails = {
  heading: "Start a project with Me today!",
  subHeading: "I am Available for Freelancer.",
  btnText: "Hire Me",
};

export const WorkProcessDetails = {
  heading: {
    title: "Work Process",
    text: "Simple work process of build your design. Delivering work within time and budget which meets client’s requirements is our moto.",
  },
  WDetails: [
    {
      sno: 1,
      name: "Planning & Consulting",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 2,
      name: "Content",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 3,
      name: "Conception",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 4,
      name: "Design & Development",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 5,
      name: "Final discussion",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 6,
      name: "Delivery & Launch",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
  ],
};

export const OurClientsDetails = {
  heading: {
    title: "Our Clients",
    text: "See what my happy clients say about me. I'm proud to have my clients.",
  },
  clients: [
    {
      name: "Jay Shah",
      img: "images/testimonial/client-sm-1.jpg",
      exp: "Founder at Icomatic Pvt Ltd",
      summary:
        " Easy to use, reasonably priced simply dummy text of theprinting and typesetting industry. Quidam lisque persiusinteresset his et, in quot quidam possim iriure. simply dummy text of the printing and typesetting industry.",
    },
    {
      name: "Patric Cary",
      img: "images/testimonial/client-sm-2.jpg",
      exp: "Freelancer from USA",
      summary:
        " “I am happy Working with printing and typesetting industry.Quidam lisque persius interesset his et, in quot quidampersequeris essent possim iriure. simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Dennis Jacques",
      img: "images/testimonial/client-sm-3.jpg",
      exp: "iDeal Inc",
      summary:
        " “Only trying it out since a few days. But up to now excellent.Seems to work flawlessly. priced simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Chris Tom",
      img: "images/testimonial/client-sm-4.jpg",
      exp: "Company CEO from UK",
      summary:
        " “I have used them twice now. Good rates, very efficientservice and priced simply dummy text of the printing andtypesetting industry quidam interesset his et. simply dummy text of the printing and typesetting industry. Excellent.”",
    },
  ],
  posters: [
    {
      id: 1,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 2,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 3,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 4,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 5,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 6,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 7,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 8,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 9,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 10,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 11,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 12,
      img: "images/clients/client-logo2.png",
    },
  ],
};

export const ContactDetails = [
  {
    icon: "fab fa-linkedin-in",
    title: "LinkedIn",
    p1: "https://www.linkedin.com/in/taylorlbird",
    href: "https://www.linkedin.com/in/taylorlbird"
  },
  {
    icon: "fas fa-file-download",
    title: "Resume/CV",
    p1: ".PDF, ATS compatible",
    href: "https://taylorbirdresume.s3.us-west-2.amazonaws.com/taylorbird-resume.pdf"
  },
  {
    icon: "fas fa-envelope",
    title: "Contact",
    p1: "taylor@taylorbird.io",
    href: "mailto:taylor@taylorbird.io"
  },
];

export const socialIcons = [

  {
    title: "Linkedin",
    icon: "fab fa-linkedin-in",
    href: "https://www.linkedin.com/in/taylorlbird",
    className: "social-icons-linkedin",
  },
  {
    title: "GitHub",
    icon: "fab fa-github",
    href: "http://www.github.com/",
    className: "social-icons-github",
  },
];