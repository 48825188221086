export const lineParticles = {
    fullScreen: {
       enable: false,
   },
   particles: {
       color: {
           value: "#ffffff",
         },
       number: {
           value: 80,
         },
       links: {
           color: "#ffffff",
           enable: true,
           opacity: 0.4,
       },
       move: {
           enable: true,
       },
       opacity: {
           value: 0.4,
         },
       size: {
           value: { min: 1, max: 5 },
       },
   },
   detectRetina: true,
 }