// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-particles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.portfolio-image-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}
.portfolio-image-modal img {
  max-width: 80%;
  max-height: 80%;
}
.portfolio-image-modal .btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACF;;AAIA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,oCAAA;AADF;AAEE;EACE,cAAA;EACA,eAAA;AAAJ;AAEE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAAJ","sourcesContent":[".hero-particles {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n  \n}\n\n//portfolio image modal\n.portfolio-image-modal {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n  background-color: rgba(0, 0, 0, 0.5);\n  img {\n    max-width: 80%;\n    max-height: 80%;\n  }\n  .btn-close {\n    position: absolute;\n    top: 15px;\n    right: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
